import apollo from "@/plugins/apollo";
import { GET_ADVERTISERS } from "@/graphql/Dashboard/Advertisers";

const list_advertisers = async (variables) => {
  const response = await apollo.query({
    query: GET_ADVERTISERS,
    variables,
  });
  return response.data.advertisers;
};

export { list_advertisers };