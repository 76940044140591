const dashboard = {
  namespaced: true,
  state: {
    search: null,
    loading: false,
  },

  getters: {
    loading: (state) => (state.loading),
  },
  mutations: {
    SET_SEARCH(state, payload) {
      state.search = payload;
    },
    SET_LOADING(state, payload) {
      state.loading = payload;
    },
  },

  actions: {
    async searchCampaigns({ commit }, payload) {
      commit("SET_SEARCH", payload);
    },
    async setLoading({ commit }, payload) {
      commit("SET_LOADING", payload);
    },
  },
};

export default dashboard;