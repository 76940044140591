import apollo from "@/plugins/apollo";
import { LIST_INSIGHTS } from "@/graphql/Dashboard/Insights";

const list_insights = async (variables) => {
  const response = await apollo.query({
    query: LIST_INSIGHTS,
    variables,
  });
  return response.data.campaigns[0].insights;
};

export { list_insights };
