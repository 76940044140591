import Vue from "vue";
import Vuex from "vuex";
import dashboard from "./modules/dashboard.module.js";
import generalDatas from "./modules/generalDatas.module.js";
import fixedInputData from "./modules/fixedInputData.module.js";
import campaign from "./modules/campaign.module.js"
import channel from "./modules/channel.module.js";
import site from "./modules/site.module.js"
import insight from "./modules/insight.module.js";



Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    dashboard,
    generalDatas,
    fixedInputData,
    campaign,
    channel,
    site,
    insight
  },
});

export default store;
