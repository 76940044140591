import { formatDate } from "@/helpers/filters";
import { loggedUser } from "@/services/auth-service";
import { list_Campaigns, total_Campaigns, createCampaign, updateCampaign } from "@/services/CampaignsList";
import moment from "moment";
import _ from "lodash";

const campaign = {
  namespaced: true,
  state: {
    campaigns: [],
    totalCampaigns: 0,
    campaign: {},
    campaignUpdate: {},
  },

  getters: {
    campaigns: (state) => (state.campaigns),
    totalCampaigns: (state) => (state.totalCampaigns),
    campaign: (state) => ({ ...state.campaign }),
    campaignUpdate: (state) => ({ ...state.campaignUpdate }),
  },

  mutations: {
    SET_CAMPAIGNS(state, payload) {
        state.campaigns = payload
    },  
    SET_TOTAL_CAMPAIGNS(state, payload) {
        state.totalCampaigns = payload
    },  

    SET_CAMPAIGN(state, payload) {
      if (
        Object.keys(payload).length > 0
      ) {
        const campaign = { ...payload };
        state.campaign = campaign;
      } else {
        state.campaign = { ...payload };
      }
    },  

    SET_CAMPAIGN_INIT(state, payload) {
      const clone = _.cloneDeep(payload);

      state.campaign = payload
      state.campaignUpdate = clone
    },  
    SET_CAMPAIGN_UPDATE(state, payload) {
      if (
        Object.keys(payload).length > 0
      ) {
        const campaignUpdate = { ...payload };
        state.campaignUpdate = campaignUpdate;
      } else {
        state.campaignUpdate = { ...payload };
      }
    }
  },

  actions: {
    async getCampaigns({ commit }, payload) {
      const res = await list_Campaigns(payload)
      commit("SET_CAMPAIGNS", res);
    },

    async findCampaign({ commit }, payload) {
      commit("SET_CAMPAIGN_INIT", {});
      const res = await list_Campaigns(payload)
      
      if (
        res[0].camapign_age_perfil &&
        res[0].camapign_age_perfil.length == 2
      ) {
        res[0].campaign_age_perfils = `${res[0].camapign_age_perfil[0]} a ${res[0].camapign_age_perfil[1]}`;
      }
      const social_profiles = await this.state.fixedInputData.social_profiles
      res[0].campaign_social_profiles_local = []
      res[0].campaign_amount_purchased_format = res[0].campaign_amount_purchased
      await res[0].campaign_social_profiles.forEach((e) => {
        if (e) {
          const id = typeof e != "number" ? e?.social_profiles_id : e;
          
          const has = social_profiles.filter(
            (val) => val.social_profiles_id == id
          );

          res[0].campaign_social_profiles_local.push(has[0]);
        }
      });

      delete res[0].__typename;
      res[0].advertiser_id = parseInt(res[0].advertiser.account_id);
      res[0].account_id = parseInt(res[0].account.account_id);
      const start = moment(res[0].campaign_start_datetime).format("DD/MM/YYYY");
      const end = moment(res[0].campaign_end_datetime).format("DD/MM/YYYY");
      
      res[0].dateCampaign = `${start} a ${end}`;
      commit("SET_CAMPAIGN_INIT", res[0]);
    },

    async getTotalCampaign({ commit }) {
      const res = await total_Campaigns()
      commit("SET_TOTAL_CAMPAIGNS", res);
    },

    async storeCampaign({ commit }, payload) {
      const advertiserObj = payload.advertiser;
      const accountrObj = payload.account;
      const campaignAgePerfilsObj = payload.campaign_age_perfils;
      const campaignSocialProfilesLocalObj = payload.campaign_social_profiles_local;
      const dateCampaignObj = payload.dateCampaign;
      const campaign_amount_purchasedObj = payload.campaign_amount_purchased_format;

      payload.campaign_contacts =
      await payload.campaign_contacts.map((el) => {
        return {
          contact_email: el.contact_email,
          contact_name: el.contact_name,
        };
      });
      delete payload.advertiser;
      delete payload.account;
      delete payload.campaign_age_perfils
      delete payload.campaign_social_profiles_local
      delete payload.dateCampaign
      delete payload.campaign_amount_purchased_format
      
      payload.campaign_status_id = 1;

      payload.campaign_reach = parseInt(payload.campaign_reach)
      payload.campaign_engagement = parseInt(payload.campaign_engagement)
      payload.campaign_relevance = parseInt(payload.campaign_relevance)

      const res = await createCampaign({ content: payload });

      payload.campaign_id = res.campaign_id;
      payload.advertiser = advertiserObj;
      payload.account = accountrObj;
      payload.campaign_age_perfils = campaignAgePerfilsObj;
      payload.campaign_social_profiles_local = campaignSocialProfilesLocalObj;
      payload.dateCampaign = dateCampaignObj;
      payload.campaign_amount_purchased_format = campaign_amount_purchasedObj;
      commit("SET_CAMPAIGN_INIT", payload);

    },

    async updatedCampaign({ commit }, payload) {
      const advertiserObj = payload.advertiser;
      const accountrObj = payload.account;
      const campaignAgePerfilsObj = payload.campaign_age_perfils;
      const campaignSocialProfilesLocalObj = payload.campaign_social_profiles_local;
      const dateCampaignObj = payload.dateCampaign;
      const campaign_amount_purchasedObj = payload.campaign_amount_purchased_format;

      payload.campaign_contacts =
      await payload.campaign_contacts.map((el) => {
        return {
          contact_email: el.contact_email,
          contact_name: el.contact_name,
        };
      });
      delete payload.advertiser;
      delete payload.account;
      delete payload.campaign_age_perfils
      delete payload.campaign_social_profiles_local
      delete payload.dateCampaign
      delete payload.campaign_amount_purchased_format
      
      payload.campaign_reach = parseInt(payload.campaign_reach)
      payload.campaign_engagement = parseInt(payload.campaign_engagement)
      payload.campaign_relevance = parseInt(payload.campaign_relevance)

      await updateCampaign({ content: payload });
      
      payload.advertiser = advertiserObj;
      payload.account = accountrObj;
      payload.campaign_age_perfils = campaignAgePerfilsObj;
      payload.campaign_social_profiles_local = campaignSocialProfilesLocalObj;
      payload.dateCampaign = dateCampaignObj;
      payload.campaign_amount_purchased_format = campaign_amount_purchasedObj;
      commit("SET_CAMPAIGN_INIT", payload);
    },

    async setCampaign({ commit }, payload) {
      commit("SET_CAMPAIGN", payload);
    },

    async setCampaignUpdate({ commit }, payload) {
      commit("SET_CAMPAIGN_UPDATE", payload);
    },
  },
};

export default campaign;
