<template>
  <div>
    <v-text-field
      v-model.lazy="value"
      v-bind="$attrs"
      @blur="formatter"
      @keydown.enter="formatter"
      @keydown.esc="clearError"
      class="ma-0 pa-0 rounded-lg"
      label="Período de veiculação"
      height="50"
      outlined
      clearable
      hide-details="auto"
      :rules="dateRangeRules"
      v-mask="'##/##/#### a ##/##/####'"
    >
    </v-text-field>
    <ModalReturn :ModalReturn="ModalReturn" />
  </div>
</template>

<script>
import moment from "moment";
import ModalReturn from "@/components/Modais/ModalReturn/ModalReturn";

export default {
  name: "text-field-date",
  components: {
    ModalReturn,
  },

  props: {
    format: {
      type: String,
    },
  },
  data() {
    return {
      ModalReturn: {},
      value: this.$attrs.value,
      dateRangeRules: [
        (v) =>
          /^\d{2}\/\d{2}\/\d{4} a \d{2}\/\d{2}\/\d{4}$/.test(v) ||
          "DD/MM/YYYY a DD/MM/YYYY",
      ],
    };
  },
  watch: {
    "$attrs.value"(newVal) {
      this.value = newVal
      this.formatter();
    },
  },
  methods: {
    async formatter() {
      if (
        this.value === undefined ||
        this.value === null ||
        this.value === ""
      ) {
        this.value = null;
        return;
      }
      const result = await this.formatDate(this.value);
      this.$emit("input", result);
      
    },

    formatDate(value) {
      if(value){
        const dateRange = value.split(" a ");
        if (this.isValidDateRange(dateRange)) {
          const [startDate, endDate] = dateRange;
          const start = moment(startDate, "DD/MM/YYYY").format("DD/MM/YYYY");
          const end = moment(endDate, "DD/MM/YYYY").format("DD/MM/YYYY");
          return `${start} a ${end}`;
        } else {
          this.ModalReturn = {
            showModal: true,
            title: "Data inválida! A data de inicio deve ser menor que a final.",
            status: "error",
          };
          return value
        }
      }
    },

    isValidDateRange(dateRange) {
      const [startDate, endDate] = dateRange;
      const dateFormat = "DD/MM/YYYY";
      const isValidStartDate = moment(startDate, dateFormat, true).isValid();
      const isValidEndDate = moment(endDate, dateFormat, true).isValid();
      

      return (
        isValidStartDate &&
        isValidEndDate &&
        moment(startDate, dateFormat).isSameOrBefore(
          moment(endDate, dateFormat)
        )
      );
    },
  },

  created() {
    this.formatter();
  },
};
</script>
