import { createChannel, upateChannel,  listChannels, deleteChannel } from "@/services/Channel";
import { list_Campaigns_sites } from "@/services/CampaignsList";
import { deleteFormat } from "@/services/Format";
import _ from "lodash";

const channel = {
  namespaced: true,
  state: {
    channels: [],
    channelsAdded: [],
  },

  getters: {
    channels: (state) => (state.channels),
    channelsAdded: (state) => (state.channelsAdded),
  },

  mutations: {
    SET_CHANNEL_ADDED(state, payload) {
        state.channelsAdded = payload
    },
    SET_CHANNEL(state, payload) {
        state.channels = payload
    },
    SET_NEW_CHANNEL(state, payload) {
        state.channels.push(payload)
    },
    UPDATE_CHANNEL(state, payload) {
      const index = state.channels.findIndex(channel => channel.id === payload.id);
      
      if (index !== -1) {
          state.channels[index] = payload;
      } else {
          state.channels.push(payload);
      }
    },
  },

  actions: {
    async storeChannel({ commit }, payload) {
      const res = await createChannel({content: payload})
      // commit("SET_NEW_CHANNEL", res);
    },
    async upatedChannel({ commit }, payload) {
      const res = await upateChannel({content: payload})
      commit("UPDATE_CHANNEL", res);
    },
    async deletedChannel({ commit }, payload) {
      const res = await deleteChannel(payload)
      // commit("UPDATE_CHANNEL", res);
    },
    async getChannels({ commit }, payload) {
      const res = await listChannels(payload)
      commit("SET_CHANNEL", res);
    },
    async getChannelsAdded({ commit }, payload) {
      const res = await list_Campaigns_sites(payload);
      const types = ['CPM', 'CPC', 'CPV'];

      res[0].sites.forEach(site => {
        const siteId = site.site_id;
        site.purchase_type = types.map(type => {
          const filteredChannels = site.channels.filter(channel => channel.channel_purchase_type === type);
          const formattedChannels = filteredChannels.map(channel => {
            const insights = channel?.insights;
            const formats = insights?.formats.map(format => ({ ...format, format_id: siteId + format.size, channel_id: channel.channel_id }))
            return { ...channel, formats };
          });
          if(formattedChannels.length){
            return {
              type_id: type + siteId,
              type,
              channels: formattedChannels
            };
          }
        });
        site.purchase_type = site.purchase_type.filter(x=>x)
      });
      commit("SET_CHANNEL_ADDED", res[0]);
    },
    async clearChannels({ commit }, payload) {
      commit("SET_CHANNEL", payload);
    },
    async deletedFormat({ commit }, payload) {
      const res = await deleteFormat(payload)
      // commit("UPDATE_CHANNEL", res);
    },
  },
};

export default channel;
