<template>
  <v-app>
    <ToolbarComponent />
    <v-main>
      <router-view />
    </v-main>
    <FooterComponent :class="{ 'footer-login': !isDashboardRoute }"  />
  </v-app>
</template>

<script>
import ToolbarComponent from "@/components/ToolbarComponent/ToolbarComponent.vue";
import FooterComponent from "@/components/FooterComponent/FooterComponent.vue";

export default {
  name: "App",

  components: {
    ToolbarComponent,
    FooterComponent,
  },

  computed: {
    isDashboardRoute() {
      return this.$route.path.includes('/dashboard');
    }
  }
};
</script>
<style lang="scss" scoped src="@/sass/general.scss"></style>
