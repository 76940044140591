import apollo from "@/plugins/apollo";
import { GET_INTERESTS } from "@/graphql/Dashboard/Interests";

const list_interests= async (variables) => {
  const response = await apollo.query({
    query: GET_INTERESTS,
    variables,
  });
  return response.data.campaigns_targets;
};

export { list_interests};