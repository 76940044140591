import apollo from "@/plugins/apollo";
import { GET_SITES, GET_SITES_TYPES } from "@/graphql/Dashboard/Site";

const list_sites = async (variables) => {
  const response = await apollo.query({
    query: GET_SITES,
    variables,
    fetchPolicy: "no-cache"
  });
  
  return response.data.sites;
};

const list_sites_types = async (variables) => {
  const response = await apollo.query({
    query: GET_SITES_TYPES,
    variables,
    fetchPolicy: "no-cache"
  });
  
  return response.data.sites_types;
};

export { list_sites, list_sites_types };
