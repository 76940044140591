import gql from "graphql-tag";

export const RECOVER_PASSWORD = gql`
  mutation Password_recovery(
    $userEmail: String
    $redirect: String
    $userPassword: String
    $passwordCheck: String
  ) {
    password_recovery(
      user_email: $userEmail
      redirect: $redirect
      user_password: $userPassword
      password_check: $passwordCheck
    ) {
      code
      message
      data
    }
  }
`;
