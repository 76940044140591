import {logout} from "@/services/auth-service.js";

import logoStatioScore from "@/assets/StationScore.png";

export default {
  name: "ToolbarComponent",

  data() {
    return {
      logoStatioScore: logoStatioScore,
      themeModeTitle: "Escuro",
      countUnreadNotifications: 10,
      isLoading: false,
      loggedIn: false,
    };
  },

  methods: {
    gotoNotifications() {
      //this.$router.push({ name: "NotificationView" });
      alert("remover comentario");
    },

    toggleTheme() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      if (this.$vuetify.theme.dark === true) {
        this.themeModeTitle = "Claro";
      } else {
        this.themeModeTitle = "Escuro";
      }
    },

    async logoutDash() {
      try {
        this.isLoading = true;
        await logout();
        localStorage.clear();
        this.$router.push("/");
      } catch (error) {
        
      } finally {
        this.isLoading = false;
      }
    },
  },
  computed: {
    countUnreadNotification() {
      if (this.countUnreadNotifications > 9) {
        return "+9";
      } else {
        return this.countUnreadNotifications;
      }
    },

    columnCount() {
      if (
        this.$route.name !== 'LoginComponent' &&
        this.$route.name !== 'SendCodeComponent' &&
        this.$route.name !== 'InsertCodeComponent' &&
        this.$route.name !== 'RecoverPasswordComponent'
      ) {
        return 6;
      } else {
        return 12;
      }
    },

    dataTeams() {
      return this.$store.state.teamData;
    },
  },
};
