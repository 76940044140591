import gql from "graphql-tag";

export const GET_INTERESTS = gql`
query GET_INTERESTS($filter: String, $orderby: String, $direction: String, $offset: Int, $limit: Int) {
  campaigns_targets(filter: $filter, orderby: $orderby, direction: $direction, offset: $offset, limit: $limit) {
    campaign_id
    target_descr
  }
}
`;
