import AuthenticationView from "@/views/Auth/AuthenticationView.vue";

export default {
  path: "/",
  component: AuthenticationView,
  meta: { requiresAuth: false },
  children: [
    {
      path: "/",
      name: "LoginComponent",
      component: () =>
        import("@/components/Auth/LoginComponent/LoginComponent.vue"),
    },
    {
      path: "send-code",
      name: "SendCodeComponent",
      component: () =>
        import("@/components/Auth/SendCodeComponent/SendCodeComponent.vue"),
    },
    {
      path: "insert-code",
      name: "InsertCodeComponent",
      component: () =>
        import("@/components/Auth/InsertCodeComponent/InsertCodeComponent.vue"),
    },
    {
      path: "recover-password",
      name: "RecoverPasswordComponent",
      component: () =>
        import(
          "@/components/Auth/RecoverPasswordComponent/RecoverPasswordComponent.vue"
        ),
    },
  ],
};
