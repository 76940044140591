import { formatDate } from "@/helpers/filters";
import { loggedUser } from "@/services/auth-service";

const generalDatas = {
  namespaced: true,
  state: {
    user: {},
    campaign: {},
    campaignUpdate: {},
  },

  getters: {
    campaign: (state) => ({ ...state.campaign }),
    campaignUpdate: (state) => ({ ...state.campaignUpdate }),
    user: (state) => (state.user),
  },

  mutations: {
    SET_CAMPAIGN(state, payload) {
      if (
        Object.keys(payload).length > 0
        ) {
          const campaign = { ...payload };
          state.campaign = campaign;
        } else {
          state.campaign = { ...payload };
        }
      },
      SET_CAMPAIGN_UPDATE(state, payload) {
      if (
        Object.keys(payload).length > 0
      ) {
        const campaignUpdate = { ...payload };
        state.campaignUpdate = campaignUpdate;
      } else {
        state.campaignUpdate = { ...payload };
      }
    },
    SET_USER(state, payload) {
      state.user = payload
    },
  },

  actions: {
    async setCampaign({ commit }, payload) {
      commit("SET_CAMPAIGN", payload);
    },

    async setCampaignUpdate({ commit }, payload) {
      commit("SET_CAMPAIGN_UPDATE", payload);
    },

    async userAuth({ commit }) {
      const res = loggedUser()
      commit("SET_USER", res);
    },
  },
};

export default generalDatas;
