import gql from "graphql-tag";

export const GET_ADVERTISERS = gql`
query GET_ADVERTISERS($filter: String, $orderby: String, $direction: String, $offset: Int, $limit: Int) {
  advertisers(filter: $filter, orderby: $orderby, direction: $direction, offset: $offset, limit: $limit) {
    account_id
    account_name
  }
}
`;
