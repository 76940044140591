import { list_sites, list_sites_types } from "@/services/Site";
import _ from "lodash";

const site = {
  namespaced: true,
  state: {
    sites: [],
    sitesTypes: [],
  },

  getters: {
    sites: (state) => (state.sites),
    sitesTypes: (state) => (state.sitesTypes),
  },

  mutations: {
    SET_SITES(state, payload) {
      state.sites = payload
    },
    SET_SITES_TYPES(state, payload) {
      state.sitesTypes = payload
    },
  },

  actions: {
    async getSites({ commit }, payload) {
      const res = await list_sites(payload)
      
      commit("SET_SITES", res);
    },
    async getSitesTypes({ commit }, payload) {
      const res = await list_sites_types()
      
      commit("SET_SITES_TYPES", res);
    },
  },
};

export default site;
