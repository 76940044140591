import apollo from "@/plugins/apollo";
import { DELETE_FORMAT } from "@/graphql/Dashboard/Format";

const deleteFormat = async (content) => {
  try {
    const response = await apollo.mutate({
      mutation: DELETE_FORMAT,
      variables: content
  })
  
    return response.data.deleteFormat;
  } catch (error) {
    
    throw error;
  }
};

export { deleteFormat };
