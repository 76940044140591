import apollo from "@/plugins/apollo";
import { CREATE_CHANNEL, UPDATE_CHANNEL, LIST_CHANNELS,DELETE_CHANNELS } from "@/graphql/Dashboard/Channel";

const createChannel = async (content) => {
  try {
    const response = await apollo.mutate({
      mutation: CREATE_CHANNEL,
      variables: content
  })
  
    return response.data.createChannel.data;
  } catch (error) {
    
    throw error;
  }
};

const upateChannel = async (content) => {
  try {
    const response = await apollo.mutate({
      mutation: UPDATE_CHANNEL,
      variables: content
  })
  
    return response.data.updateChannel.data;
  } catch (error) {
    
    throw error;
  }
};

const deleteChannel = async (content) => {
  try {
    const response = await apollo.mutate({
      mutation: DELETE_CHANNELS,
      variables: content
  })
  
    return response.data.deleteChannel;
  } catch (error) {
    
    throw error;
  }
};

const listChannels = async (variables) => {
  const response = await apollo.query({
    query: LIST_CHANNELS,
    variables,
    fetchPolicy: "no-cache"
  });
  return response.data.channels;
};
export { createChannel, listChannels, upateChannel, deleteChannel };
