import { formatError } from "@/helpers/filters";
import { login } from "@/services/auth-service";

export default {
  name: "LoginComponent",

  data() {
    return {
      user: {
        userEmail: null,
        userPassword: null,
      },
      errorMessageLogin: "",
      dialog: false,
      checkbox: false,
      shakeAnimated: false,
      isLoading: false,
      rulesEmail: [
        (value) => !!value || "Campo obrigatório",
        (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Email inválido";
        },
      ],

      rulesPassword: [
        (value) => !!value || "Campo obrigatório",
        (value) => (value && value.length >= 8) || "Min 8 caracteres",
      ],
    };
  },

  methods: {
    async authenticateUser() {
      if (!this.user.userEmail || !this.user.userPassword) {
        this.shakeAnimated = true;
        setTimeout(() => {
          this.shakeAnimated = false;
        }, 1000);
      } else {
        try {
          localStorage.clear();
          this.isLoading = true;
          await login(this.user);
          if (this.$route.path !== "/dashboard")
            this.$router.push(this.$route.query.redirect || "/dashboard");
        } catch (error) {
          this.errorMessageLogin = formatError(error.message);
          this.dialog = true;
          setTimeout(() => {
            this.dialog = false;
          }, 3000);
        } finally {
          this.isLoading = false;
        }
      }
    },
  },

  beforeMount() {
    if (localStorage.sessionId && this.$route.path !== "/dashboard") {
      this.$router.push(this.$route.query.redirect || "/dashboard");
    } else {
      if (this.$route.path !== "/")
        this.$router.push({ name: "LoginComponent" });
    }
  },
};
