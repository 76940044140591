export default async function (to, from, next) {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    try {
      if (localStorage.getItem("sessionId")) {
        // O usuário está autenticado
        next();
      } else {
        // O usuário não está autenticado, redireciona para a página de login
        next({
          path: "/",
          query: { redirect: to.fullPath },
        });
      }
    } catch (error) {
      
      next({
        path: "/",
        query: { redirect: to.fullPath },
      });
    }
  } else {
    // A rota não requer autenticação, permite o acesso
    next();
  }
}
