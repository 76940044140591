import gql from "graphql-tag";

export const GET_SITES = gql`
query GET_SITES($filter: String, $orderby: String, $direction: String, $offset: Int, $limit: Int) {
  sites(filter: $filter, orderby: $orderby, direction: $direction, offset: $offset, limit: $limit) {
    site_id
    site_name
    site_website
  }
}
`;

export const GET_SITES_TYPES = gql`
query Sites_types {
  sites_types {
    site_type_id
    type_name
    type_descr
  }
}
`;
