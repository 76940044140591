export default {
  name: "FooterComponent",

  data: () => ({}),
  computed: {
    marginTop() {
      if (
        this.$route.name !== 'LoginComponent' &&
        this.$route.name !== 'SendCodeComponent' &&
        this.$route.name !== 'InsertCodeComponent' &&
        this.$route.name !== 'RecoverPasswordComponent'
      ) {
        return "mt-10";
      } else {
        return "mt-0";
      }
    },
  },
};
