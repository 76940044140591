import gql from "graphql-tag";

export const CREATE_CHANNEL = gql`
mutation CREAYE_CHANNEL($content: ChannelContent!) {
    createChannel(content: $content) {
      code
      message
      success
      data
    }
}
`;

export const UPDATE_CHANNEL = gql`
mutation UPDATE_CHANNEL($content: ChannelContent!) {
  updateChannel(content: $content) {
    code
    data
    message
    success
  }
}
`;

export const LIST_CHANNELS = gql`
query LIST_CHANNELS($filter: String, $orderby: String, $direction: String, $offset: Int, $limit: Int) {
    channels(filter: $filter, orderby: $orderby, direction: $direction, offset: $offset, limit: $limit) {
      channel_id
      site_id
      campaign_id
      channel_descr
      channel_start_datetime
      channel_end_datetime
      channel_formats
      channel_amount_purchased
      channel_media_type
      channel_purchase_type
    }
  }
`;

export const DELETE_CHANNELS = gql`
mutation DELETE_CHANNELS($content: ChannelContent, $campaignId: Int, $siteId: Int) {
  deleteChannel(content: $content, campaign_id: $campaignId, site_id: $siteId) {
    code
    data
    message
    success
  }
}
`;