/* eslint-disable no-unused-vars */
import Vue from "vue";
import VueRouter from "vue-router";
import auth from "./auth";
import dashboard from "./dashboard";
import authGuard from "./authGuard";

Vue.use(VueRouter);

const routes = [
  auth,
  dashboard,
  {
    path: "*",
    redirect: "/",
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
  scrollBehavior(to, from, savePosition) {
    return {
      x: 0,
      y: 0,
    };
  },
});

router.beforeEach(authGuard);

export default router;
