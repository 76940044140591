import Vue from "vue";
import App from "./App.vue";
import router from "./router/routes";
import store from "./store";
import vuetify from "./plugins/vuetify";
import VueMask from 'v-mask'
import VTextFieldDate from "@/components/CustomComponents/text-field-date.vue"

Vue.config.productionTip = false;
Vue.component('v-text-field-date', VTextFieldDate);
Vue.use(VueMask);

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
