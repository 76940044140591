import gql from "graphql-tag";

export const LIST_CAMPAIGNS = gql`
  query LIST_CAMPAIGNS(
    $filter: String
    $orderby: String
    $direction: String
    $offset: Int
    $limit: Int
  ) {
    campaigns(
      filter: $filter
      orderby: $orderby
      direction: $direction
      offset: $offset
      limit: $limit
    ) {
      campaign_id
      producer_id
      campaign_status_id
      campaign_amount_purchased
      campaign_start_datetime
      campaign_end_datetime
      campaign_name
      campaign_comments
      campaign_redirect_url
      campaign_social_profiles
      campaign_genders
      camapign_age_perfil
      campaign_locations
      campaign_targets
      campaign_reach
      campaign_engagement
      campaign_relevance
      account {
        account_name
        account_id
      }
      campaign_contacts {
        contact_account_id
        contact_advertiser_id
        contact_email
        contact_group_id
        contact_name
        contact_phone
      }
      advertiser {
        account_id
        account_name
        metadata
      }
    }
  }
`;

export const LIST_CAMPAIGNS_SITE = gql`
query LIST_CAMPAIGNS_SITE($campaignId: Int!, $filter: String, $channelsCampaignId2: Int!, $insightsFilter2: String) {
  campaigns(filter: $filter) {
    campaign_id
    campaign_channels_amount_purchased
    campaign_amount_purchased
    insights
    estimated_insights(filter: $insightsFilter2)
    sites {
      site_id
      site_name
      insights(campaign_id: $campaignId, filter: $insightsFilter2)
      channels(campaign_id: $channelsCampaignId2) {
        channel_id
        channel_descr
        channel_amount_purchased
        channel_formats
        channel_media_type
        channel_purchase_type
        insights(filter: $insightsFilter2)
        channel_end_datetime
        channel_start_datetime
        campaign_id
        site_id
      }
    }
  }
}
`;

export const TOTAL_CAMPAIGNS = gql`
  query TOTAL_CAMPAIGNS {
    count_campaigns
  }
`;

export const CREATE_CAMPAIGNS = gql`
mutation CREATE_CAMPAING($content: CampaignContent!) {
  createCampaign(content: $content) {
    data
    code
    message
    success
  }
}
`;

export const UPDATE_CAMPAIGNS = gql`
mutation UPDATE_CAMPAIGNS($content: CampaignContent!) {
  updateCampaign(content: $content) {
    code
    data
    message
    success
  }
}
`;
