import moment from "moment/moment";
import "moment/locale/pt-br";

const formatMoney = (value) => {
  if (value) {
    const result = value.toString().replace(/[^\d-.]/g, "");
    
    const formattedResult = parseFloat(result).toLocaleString("pt-BR", {
      style: 'currency', currency: 'BRL'
    });
    
    return formattedResult;
  } else{
    return 'R$'
  }
};

const normalizeString = (str) => {
  return str
      .normalize('NFD')           // Remove os acentos
      .replace(/[\u0300-\u036f]/g, '')
      .toLowerCase()              // Converte para minúsculas
      .trim();                    // Remove espaços em branco antes e depois
}

const formatError = (message) => {
  const messageSplit = message.split(":");
  return messageSplit[messageSplit.length - 1].trim();
};

const formatDate = (value) => {
  if (value && moment.unix(value).isValid()) {
    const isMilliseconds = value.toString().length > 10;
    const timestampInSeconds = isMilliseconds ? value / 1000 : value;

    return moment.unix(timestampInSeconds).format("DD/MM/YYYY");
  } else {
    return moment(value).format("DD/MM/YYYY");
  }
};

const formatNumber = (value) => {
  if (!value) {
    return "-";
  } else {
    return parseInt(value).toLocaleString("pt-br");
  }
};

const formatNumberPercent = (value) => {
  if (!value) {
    return 0;
  } else {
    return (value * 100).toFixed(2).toLocaleString("pt-br");
  }
};

const formatNumberPercentVA = (value) => {
  if (!value) {
    return 0;
  } else {
    return value.toFixed(2).toLocaleString("pt-br");
  }
};

const camelize = (str) => {
  if (typeof str !== "undefined" && str) {
    if (str.length > 4) {
      return str.replace(/^[a-z]|[A-Z]|[A-zÀ-ÖØ-öø-ÿ]/g, function (v, i) {
        let chr = i === 0 ? v.toUpperCase() : " " + v.toLowerCase();
        return chr.trim();
      });
    } else {
      return str.replace(/^[a-z]|[A-Z]|[A-zÀ-ÖØ-öø-ÿ]/g, function (v, i) {
        let chr = i === 0 ? v.toUpperCase() : " " + v.toUpperCase();
        return chr.trim();
      });
    }
  } else {
    return str;
  }
};

const truncate = (str, length) => {
  if (str && str.length > length) {
    return str.substring(0, length) + "...";
  } else {
    return str;
  }
};

export {
  formatDate,
  formatNumber,
  formatNumberPercent,
  formatError,
  camelize,
  truncate,
  formatMoney,
  formatNumberPercentVA,
  normalizeString
};
