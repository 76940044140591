import gql from "graphql-tag";

export const LOGGED_USER = gql`
query Logged_user {
  logged_user {
    user_id
    account_id
    user_name
    user_email
  }
}
`;
