import apollo from "@/plugins/apollo";
import {
  LIST_CAMPAIGNS,
  LIST_CAMPAIGNS_SITE,
  TOTAL_CAMPAIGNS,
  CREATE_CAMPAIGNS,
  UPDATE_CAMPAIGNS
} from "@/graphql/Dashboard/CampaignsList";

const list_Campaigns = async (variables) => {
  const response = await apollo.query({
    query: LIST_CAMPAIGNS,
    variables,
    fetchPolicy: "no-cache"
  });
  return response.data.campaigns;
};

const list_Campaigns_sites = async (variables) => {
  const response = await apollo.query({
    query: LIST_CAMPAIGNS_SITE,
    variables,
    fetchPolicy: "no-cache"
  });
  return response.data.campaigns;
};

const total_Campaigns = async () => {
  const response = await apollo.query({
    query: TOTAL_CAMPAIGNS,
  });
  return response.data.count_campaigns;
};

const createCampaign = async (content) => {
  try {
    const response = await apollo.mutate({
      mutation: CREATE_CAMPAIGNS,
      variables: content
  })
  
    return response.data.createCampaign.data;
  } catch (error) {
    
    throw error;
  }
};

const updateCampaign = async (content) => {
  try {
    const response = await apollo.mutate({
      mutation: UPDATE_CAMPAIGNS,
      variables: content,
      fetchPolicy: 'no-cache',
    })
    return response.data.updateCampaign.data;
  } catch (error) {
    
    throw error;
  }
};
export { list_Campaigns, total_Campaigns, createCampaign, updateCampaign, list_Campaigns_sites };
