import { list_advertisers } from "@/services/Advertiser";
import { list_interests } from "@/services/Interests";

const fixedInputData = {
  namespaced: true,
  state: {
    social_profiles: [
      {
        social_profiles_id: 1,
        social_profiles_name: "Classe A",
      },
      {
        social_profiles_id: 2,
        social_profiles_name: "Classe B",
      },
      {
        social_profiles_id: 3,
        social_profiles_name: "Classe C",
      },
      {
        social_profiles_id: 4,
        social_profiles_name: "Classe D",
      },
      {
        social_profiles_id: 5,
        social_profiles_name: "Classe E",
      },
    ],

    regions: {
      Norte: [
        { nome: "Acre", sigla: "AC" },
        { nome: "Amapá", sigla: "AP" },
        { nome: "Amazonas", sigla: "AM" },
        { nome: "Pará", sigla: "PA" },
        { nome: "Rondônia", sigla: "RO" },
        { nome: "Roraima", sigla: "RR" },
        { nome: "Tocantins", sigla: "TO" },
      ],
      Nordeste: [
        { nome: "Alagoas", sigla: "AL" },
        { nome: "Bahia", sigla: "BA" },
        { nome: "Ceará", sigla: "CE" },
        { nome: "Maranhão", sigla: "MA" },
        { nome: "Paraíba", sigla: "PB" },
        { nome: "Pernambuco", sigla: "PE" },
        { nome: "Piauí", sigla: "PI" },
        { nome: "Rio Grande do Norte", sigla: "RN" },
        { nome: "Sergipe", sigla: "SE" },
      ],
      "Centro-Oeste": [
        { nome: "Distrito Federal", sigla: "DF" },
        { nome: "Goiás", sigla: "GO" },
        { nome: "Mato Grosso", sigla: "MT" },
        { nome: "Mato Grosso do Sul", sigla: "MS" },
      ],
      Sudeste: [
        { nome: "Espírito Santo", sigla: "ES" },
        { nome: "Minas Gerais", sigla: "MG" },
        { nome: "Rio de Janeiro", sigla: "RJ" },
        { nome: "São Paulo", sigla: "SP" },
      ],
      Sul: [
        { nome: "Paraná", sigla: "PR" },
        { nome: "Rio Grande do Sul", sigla: "RS" },
        { nome: "Santa Catarina", sigla: "SC" },
      ],
      "Não identificada": [
        { nome: "Não identificada", sigla: "-" },
      ],
    },

    gender: ["Homens","Mulheres"],

    interests: [],

    advertisers: [],
  },

  mutations: {
    SET_DATA_ADVERTISER(state, payload) {
      state.advertisers = payload;
    },
    SET_DATA_INTERESTS(state, payload) {
      let interestArr = []
      for (const interest of payload) {
        interestArr.push(interest.target_descr)
      }
      state.interests = interestArr;
    },
  },

  actions: {
    async getAdvertiser({ commit }, payload) {
      try {
        const advertisers = await list_advertisers({
          filter: null,
          orderby: null,
          direction: null,
          offset: null,
          limit: -1,
        });
        commit("SET_DATA_ADVERTISER", advertisers);
      } catch (error) {}
    },

    async getInterests({ commit }, payload) {
      try {
        const interests = await list_interests({
          filter: null,
          orderby: null,
          direction: null,
          offset: null,
          limit: -1,
        });
        commit("SET_DATA_INTERESTS", interests);
      } catch (error) {}
    },
  },
};

export default fixedInputData;
