import gql from "graphql-tag";

export const SEND_CODE = gql`
  mutation Login($userEmail: String!) {
    login(user_email: $userEmail) {
      code
      data
      message
    }
  }
`;
