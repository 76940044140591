import gql from "graphql-tag";

export const LOGOUT = gql`
  mutation Login {
    logout {
      code
      data
      message
    }
  }
`;
