import { list_insights } from "@/services/Insights";

const insight = {
    namespaced: true,
    state: {
        insights: [],
        formats: [],
    },
  
    getters: {
        insights: (state) => (state.insights),
        formats: (state) => (state.formats),
    },
    mutations: {
      SET_INSIGTHS(state, payload) {
        state.insights = payload;
      },
      SET_FORMATS(state, payload) {
        state.formats = payload;
      },
    },
  
    actions: {
      async getInsigths({ commit }, payload) {
        const res = await list_insights(payload);

        var resFormat = await res.map(el => {
            if(el?.site){
                return {
                    ...el,
                    site_name: el?.site?.site_name,
                    site_id: el?.site?.site_id,
                }
            }
        })

        commit("SET_INSIGTHS", resFormat);
      },

      async getFormats({ commit }, payload) {
        commit("SET_FORMATS", payload);
      },
    },
  };
  
  export default insight;