export default {
  path: "/dashboard",
  name: "DashboardView",
  component: () => import("@/views/DashboardView.vue"),
  meta: { requiresAuth: true },
  children: [
    {
      path: "",
      redirect: { name: "CampaignsList" },
    },
    {
      path: "campaigns-list",
      name: "CampaignsList",
      component: () => import("@/components/CampaignsList/CampaignsList.vue"),
    },
    {
      path: "register-campaign/:campaign_id?",
      name: "RegisterCampaign",
      component: () =>
        import("@/components/RegisterCampaign/RegisterCampaign.vue"),
    },
    {
      path: "sites-ranking/:campaign_id?",
      name: "SitesRanking",
      component: () => import("@/components/SitesRanking/SitesRanking.vue"),
    },
    {
      path: "campaign-planning/:campaign_id?",
      name: "CampaignPlanning",
      component: () =>
        import("@/components/CampaignPlanning/CampaignPlanning.vue"),
    },
  ],
};
