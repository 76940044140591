import Vue from "vue";
import Vuetify from "vuetify/lib";

Vue.use(Vuetify);

import pt from "vuetify/lib/locale/pt";

const vuetify = new Vuetify({
  lang: {
    locales: { pt },
    current: "pt",
  },
  theme: {
    themes: {
      light: {
        graySpaceBackground: "#44505E",
        grayMaxBackground: "#EEEEEE",
        grayMediumSpace: "#E0E0E0",
        lightGray: "#F3F4F6",
        blueSpace: "#2196F3",
        graySpace: "#44505E",
        white: "#FFFFFF",
        anchor: "#8c9eff",
        accent: "#2196F3",
        cpm: "#3C9E00",
        cpc: "#FF7A00",
        cpv: "#0C5CD4",
      },
      dark: {
        graySpaceBackground: "#1A1A1A",
        grayMaxBackground: "#EEEEEE",
        grayMediumSpace: "#232323",
        lightGray: "#212121",
        blueSpace: "#2196F3",
        graySpace: "#C9C9C9",
        white: "#FFFFFF",
        anchor: "#8c9eff",
        accent: "#2196F3",
        cpm: "#3C9E00",
        cpc: "#FF7A00",
        cpv: "#0C5CD4",
      },
    },
  },
});

export default vuetify;
