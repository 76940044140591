import gql from "graphql-tag";

const LIST_INSIGHTS = gql`
query LIST_INSIGHTS($filter: String, $orderby: String, $direction: String, $offset: Int, $limit: Int, $insightsFilter2: String) {
  campaigns(filter: $filter, orderby: $orderby, direction: $direction, offset: $offset, limit: $limit) {
    campaign_id
    insights(filter: $insightsFilter2)
  }
}
`;

export { LIST_INSIGHTS };
