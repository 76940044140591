import gql from "graphql-tag";

export const DELETE_FORMAT = gql`
mutation DELETE_FORMAT($channelId: Int!, $type: String!, $size: String!) {
  deleteFormat(channel_id: $channelId, type: $type, size: $size) {
    code
    message
    success
    data
  }
}
`;