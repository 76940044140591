import gql from "graphql-tag";

export const LOGIN = gql`
  mutation Login($userEmail: String!, $userPassword: String) {
    login(user_email: $userEmail, user_password: $userPassword) {
      code
      data
      message
    }
  }
`;
